import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import posed from 'react-pose'
import Link from "./localizedLink"
import { LocaleContext } from './layout'
import Img from 'gatsby-image'

import useTranslations from './useTranslations'

const Gallery = posed.div({
  enter: { staggerChildren: 50, delayChildren: 800 },
  exit: { staggerChildren: 50, staggerDirection: -1 }
})

const GalleryItem = posed.div({
  enter: { scale: 1, opacity: 1, delay: 0 },
  exit: { scale: 0.95, opacity: 0, delay: 0 }
})

const List = ({ type, collection, tag }) => {
  const { locale } = React.useContext(LocaleContext)
  const { allMdx } = useStaticQuery(query)
  const { ver_vestido, ver_coleccion } = useTranslations()
  const vestidos = allMdx.edges.map(item => {
    return {
      title: item.node.frontmatter.title,
      slug: item.node.frontmatter.slug,
      type: item.node.frontmatter.type,
      collection: item.node.frontmatter.collection,
      banner: item.node.frontmatter.banner,
      locale: item.node.fields.locale,
      tag: item.node.frontmatter.tag
    }
  })
  const translations = vestidos.filter(
    lang => lang.locale === locale 
    && ( 
      type ? lang.type === type : null || 
      collection ? lang.collection === collection : null ||
      tag ? lang.tag === tag : null 
      ) 
    )
  return (
    <>
      <Gallery className={`gallery ${type === `coleccion` ? 'gallery--columns-1' : 'gallery--columns-2'}`}
      >
        {translations.map((post) => (
          
          <GalleryItem key={post.slug} className="gallery__item">
            <article className={`card ${type === 'coleccion' ? 'card--colecciones' : 'card--vestido'}`}>
              <div className="card__caption">
                <h2 className="card__title">{post.title}</h2>
                  <Link to={`/${post.slug}`} className={`card__button ${type === 'coleccion' ? 'button' : null}`}>{type === 'coleccion' ? ver_coleccion : ver_vestido}</Link>
              </div>
              <Link to={`/${post.slug}`} >
                {post.banner && (
                  <Img
                    className="card__image"
                    sizes={post.banner.childImageSharp.sizes}
                  />
                )}
              </Link>
            </article>
          </GalleryItem>
          
        ))}
      </Gallery>
    </>
  )
};

export default List;

const query = graphql`
  query {
    allMdx(
      sort: {
        fields: [frontmatter___date]
        order: DESC
      }
    ) {
      edges {
        node {
          fields {
            locale
          }
          frontmatter {
            title
            slug
            type
            collection
            tag
            banner {
              childImageSharp {
                sizes(maxWidth: 720) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`;
