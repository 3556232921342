import React from 'react'
import { MDXProvider } from '@mdx-js/react'

// import each component (these all live in the same 'components' folder)

import LocalizedLink from './localizedLink'
import List from './list'
import Action from './button-action'
import ContactForm from './contact-form'
import Mapa from './mapa'

// set up a dictionary of imported components
const GlobalScopeComponents = {
  LocalizedLink: LocalizedLink,
  List: List,
  Action: Action,
  ContactForm: ContactForm,
  Mapa: Mapa
}

// export aa MDXWrapper component, which will pass these components into the child mdx posts
export const MDXWrapper = ({ children }) => {
  return (
    <MDXProvider components={{ ...GlobalScopeComponents }}>
      {children}
    </MDXProvider>
  )
}