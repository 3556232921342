import React from "react"
import ContactForm from './contact-form'
import { LocaleContext } from '../components/layout'

class ButtonAction extends React.Component {
  static contextType = LocaleContext
  state = {
    isActive: false
  }
  handleClick = () => {
    this.setState(state => ({ isActive: !state.isActive }))
  }
  render() {
    const { locale } = this.context 

    return (
      <>
      <button 
        className="button button--action"
        onClick={this.handleClick}
      >
        {locale === 'es' ? 'Pedir cita' : 'Make an appointment'}
      </button> 
      <div className={`dialog ${this.state.isActive ? 'is-open' : ''}`}>
        <div className="dialog__inner">
          <div className="dialog__close">
            <button className="button" onClick={this.handleClick}>
              ⤬
            </button>
          </div>
          <ContactForm tag={this.context.location.pathname} type="pedircita" />
        </div>
      </div>
      </>
    )
  }
}

export default ButtonAction
